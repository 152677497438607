<template>
  <div class="page-box">
    <p class="pp-p">1.全文求助是为不方便阅读全文的求助者提供一个便捷通道，提供文献互助分享。</p>
    <p class="pp-p">2.大家有时候不在单位或学校导致下载文献不方便，或者遇到公网上很难下载到的全文，这时候有人能够帮助和分享是一件很愉快的事情，为了使乐于帮助的人有动力，给予应助者一定的奖励。</p>
    <p class="pp-p">3.奖励简单分为5类:</p>
    <ul class="pp-sub-p">
      <li>（1）第1名（价格300元以内）</li>
      <li>（2）第2名~第10名（价格50元以内）</li>
      <li>（3）第11名~第30名（价格30元以内）</li>
      <li>（4）第31名~100名（价格10元以内）</li>
      <li>（5）第100名后，阳光普照，献者有份（价格10元以内随机抽取）</li>
    </ul>
    <p class="pp-p">4.奖品可以自己选择并上传，满足自己所好。</p>
    <p class="pp-p">5.奖励每半年举行一次，应助数清零。</p>
    <p class="pp-p">以上规则为试行版，最后由大家讨论投票决定。</p>
  </div>
</template>
<script>
export default {
  mounted () {
    document.title = '文献求助说明'
  }
}
</script>
<style lang="less">
.pp-p {
  font-size: 14px;
  margin: 10px 0;
  text-indent: 28px;
}
.pp-sub-p {
  list-style: none;
  font-size: 14px;
  padding-left: 44px;
  li {
    line-height: 22px;
  }
}
</style>
